export const setCookie = (name, value, hours) => {
    if (!document) {
        console.error('setCookie: document is not defined');
        return;
    }

    const expiration = new Date();
    expiration.setTime(expiration.getTime() + (hours * 60 * 60 * 1000));
    value = escape(value) + ((hours == null) ? "" : "; expires=" + expiration.toUTCString());
    document.cookie = `${name}=${value}`;
}

export const getCookie = (name) => {
    if (!document) {
        console.error('getCookie: document is not defined');
        return;
    }

    let x;
    let y;
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        x = cookies[i].substr(0, cookies[i].indexOf("="));
        y = cookies[i].substr(cookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === name) {
            return unescape(y);
        }
    }
}

export const removeCookie = (name) => {
    if (!document) {
        console.error('removeCookie: document is not defined');
        return;
    }

    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const setCookiePreferences = (data = 'all') => {
    if (data === 'all') {
        data = {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            required: 'granted'
        }
    }

    if (typeof gtag !== 'undefined') {
        gtag('consent', 'update', data);
    }

    setCookie('nextCookiePreferences', JSON.stringify(data), 365 * 24);
};

const defaultCookies = {
    ad_storage: 'denied',
    analytics_storage: 'denied'
};

export const getCookiePreferences = (req) => {
    const hasCookiePreferences = !!req.cookies.nextCookiePreferences;
    const cookiePreferences = hasCookiePreferences ? JSON.parse(req.cookies.nextCookiePreferences) : defaultCookies;

    return {
        hasCookiePreferences,
        cookiePreferences
    }
}

export const setLocaleCookies = (locale) => {
    if (document) {
        document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`
    }
}